#credits {
    height: 100%;
    margin-top: 10rem;
  }
  
  .ending-credits {
    font-family: "NTR", sans-serif;
    color: #ccd6f6;
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding-bottom: 40px;
  }
 