.sidebar-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    right: 0;
    padding-right: 5%;
    bottom: 3%;
    background-color: transparent;
  }
  
  .rs-sidenav-subtle {
    background-color: transparent !important;
  }
  
  .sidebar-links {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-family: "NTR", sans-serif;
    letter-spacing: 0em;
    line-height: 1.6em;
    font-size: 16px;
    padding: 0.2em;
    font-weight: bold;
  }
  
  a {
    text-decoration: none !important;
    color: #64ffda !important;
  }
  
  a:hover {
    color: #ccd6f6 !important;
  }
  
  .sidebar-logos {
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
  }
  

  
  @media only screen and (max-width: 600px) {
    .sidebar-nav {
      width: 100%;
      height: 50px;
    }
  
    .sidebar-links {
      padding-left: 20px;
      flex-direction: row;
      text-align: right;
      font-size: 9px;
    }
  
    .sidebar-logos {
      padding-top: 0px;
      width: 100px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-left: 50%;
    }
  
    img {
      height: 12px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .sidebar-nav {
      position: unset;
      align-items: center;
    }
    .sidebar-logos {
      width: 70px;
      padding-left: unset;
    }
  }
  