* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  font-size: 62.5%;
}
body {
  font-family: "NTR", sans-serif;
  background: #0a192f;
  

}
#content {
  width: 90vw;
  overflow: hidden;
}
::-webkit-scrollbar-track
{
	background-color: #0a192f;
}

::-webkit-scrollbar
{
	width: 0.6rem;
	
	background-color: #8892b0;
}

::-webkit-scrollbar-thumb
{
	border-radius: 1rem;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #8892b0;
	
}
