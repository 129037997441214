#projects{
    height: 100%;
   
}
.projects-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 5rem;
    font-weight: 600;
    color: #ccd6f6;
    padding-left: 7%;
  }
  .projects-num {
    font-family: "Dosis", sans-serif;
    font-size: 4rem;
    color: #64ffda;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  hr {
    width: 25%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 0.15rem solid #233554;
    margin-left: 2rem;
  }
 .project-container{
  padding-left: 11%;
  padding-right: 15%;
 } 
.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 0.5fr));
  grid-gap: 10px;
}
@media (max-width: 1080px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.projects-card {
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  background-color: #10213E;
  border-radius: 16px;
}

.projects-card:hover {
  transform: translateY(-7px);
  background-color: #10213E;
}

.card-header {
  margin-top: -20px;
  display: flex;
  padding: 1.25em 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
}
.folder-icon {
  color: #64ffda;
}

.github-icon {
  margin-top: 6px;
}
.github-icon:hover {
  color: #64ffda;
}

.open-icon {
  margin-left: 10px;
}

.card-title {
  font-family: "NTR", sans-serif;
  color: #CCD6F6;
  font-size: 24px;
  font-weight: bold;
}

.card-desc {
  font-family: "NTR", sans-serif;
  color: #889297;
  font-size: 18px;
  padding-bottom: 40px;
}

.card-tech {
  font-family: "NTR", sans-serif;
  color: #889297;
  font-size: 16px;
}




@media (min-width: 768px) and (max-width: 1023px){
  .projects-heading {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 600px) {
  #projects {
    height: unset;
    padding-left: unset;
    padding-right: unset;
  }
  .projects-heading {
    font-size: 1.5rem;
  
  }
  .projects-num {
    font-size: 2rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
  hr {
    width: 30%;
    margin-left: 1rem;
  }
  .project-container {
    margin-left: 15%;
    
  }
 

}

  