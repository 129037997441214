.hamburger {
  display: none;
}

.nav-wrapper {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-left: -4.5rem;
 padding-left: 8rem;
 padding-right: 5rem;

}
 .active {
  background-color:#0A192F;
  margin-top: 0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);

 
}


.logo {
  width: 4rem;
  border: 0.2rem solid #64ffda;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1rem;
  
}
.navitems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  list-style: none;
  cursor: pointer;
}
.num {
  font-family: "Dosis", sans-serif;
  font-size: 1.3rem;
  color: #64ffda;
}
a {
  text-decoration: none;
  color: #eee;
  font-size: 1.6rem;
  font-weight: 400;
}
.btn {
  background: #0a192f;
  color: #64ffda;
  padding: 0.5rem 1.5rem;
  border: 0.1rem solid #64ffda;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 300;
  cursor: pointer;
  padding: 1rem 1.5rem 1rem 1.5rem;
}
.btn:hover {
  background: #64ffda;
  color: #0a192f;
  border: 0.1rem solid #0a192f;
}
@media (min-width: 0px) and (max-width: 768px) {
  .nav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 0;
    margin-top: 0;
  }
  .nav-wrapper.active{
    background-color:#0A192F;
    margin-top: 0;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    margin-left: 0;
  }
  .hamburger {
    display: block;
    position: absolute;
    right: 1.5rem;
    top: 3rem;
    transform: translateY(-50%);
    z-index: 2;
    font-weight: 400;
   
  }
  .logo-wrapper {
  display: none;
  }
  .navitems {
    position: fixed;
    left: 120%;
    top: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100vh;
    z-index: 1;
    text-align: center;
    transition: 0.3s;
    
    
  }
  .navitems.active {
    left: 0;
    justify-content: unset;
    margin: 0;
  }
  .nav-item {
    margin: 0;
    padding: 5px 0 !important;
    margin-left: 20px !important;
    text-align: left;
    font-size: 35px;
  }
  .nav-item a:hover {
    border-bottom: unset;
  }
  .nav-item .active {
    border-bottom: unset;
  }

}
