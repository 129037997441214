#about {
  height: 100vh;
}
.about-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 5rem;
  font-weight: 600;
  color: #ccd6f6;
  padding-left: 7%;
  
}
.about-num {
  font-family: "Dosis", sans-serif;
  font-size: 4rem;
  color: #64ffda;
  margin-right: 2rem;
  margin-bottom: 1rem;
}
hr {
  width: 25%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.15rem solid #233554;
  margin-left: 2rem;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
}
.highlight{
    color: #64ffda;
}

.about-text{
    font-size: 2rem;
    font-weight: 400;
    color: #808aa7;
    max-width: 50%;
}

.about-description ul {
  padding-left: 4rem;
  padding-top: 20px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 10px));
}

.about-description li {
  position: relative;
  padding-left: 20px;
  font-size: 18px;

}

.about-description li::before {
  content: "▹    ";
  color: #64ffda;
  position: absolute;
  left: 0;
}
.about-image {
  padding-left: 40px;
}

.about-image img {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}


.about-description {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  max-width: 600px;
  font-size: 22px;
  text-align: justify;
  margin: 0;
}

.about-description a,
.about-description b {
  font-size: 21px;
  font-weight: bold;
}

@media (min-width: 576px) and (max-width: 1023px){
#about {
  height: 100%;
}
.container{
   padding-left: 5rem;
}
.about-image{
  margin-left: 2rem;
}
}
@media (min-width: 0px) and (max-width: 575.98px) {
  #about {
    height: 100%;
    
  }
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .about-heading {
    font-size: 3rem;
    padding-left: 5%;
  }
  .about-num {
    font-size: 2rem;
    margin-right: 1rem;
  }
  .about-text {
    font-size: 1.5rem;
    max-width: 100%;
  }
  .about-description {
    font-size: 1.5rem;
  }
  .about-description ul {
    grid-template-columns: repeat(2, minmax(150px, 10px));
  }
  .about-image img {
    max-width: 200px;
  }
  .about-image {
    padding-left: 0px;
  }

}